<template>
	<div class="people-page">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">All people using HeyGov</h2>
			</div>
		</div>

		<div class="bar-filters border rounded bg-white p-1 mb-2">
			<div class="row align-items-center g-2">
				<div class="col">
					<input
						class="form-control form-control-sm"
						type="search"
						placeholder="🔍 Search"
						v-model="filters.search"
					/>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.jurisdiction_id">
						<option value="">Any jurisdiction</option>
						<option disabled>nothing to see here 😐</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.role">
						<option value="">Any role</option>
						<option value="ADMIN">HeyGov Admin</option>
						<option value="EDITOR">Department Admin</option>
						<option value="WORKER">Department Member</option>
						<option value="CITIZEN">Resident</option>
						<option value="BOT">BOT</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.app">
						<option value="">Used phone apps</option>
						<option value="1">Yes</option>
						<option value="0">No</option>
					</select>
				</div>
			</div>
		</div>
		<div class="py-2 rounded text-center mb-2">
			<stats-bar-chart
				v-if="states.stats === 'loaded'"
				:data="statsChart"
				:options="statsChartOptions"
				:height="250"
			></stats-bar-chart>
			<div v-else class="text-center bg-light rounded-1 py-5" style="min-height: 250px">
				<p>Generating fresh stats for you</p>
				<span class="spinner-border" role="status"></span>
			</div>
		</div>

		<div class="my-2 bg-white rounded px-3 py-2 shadow-sm">
			<div class="row align-items-center" style="height:35px">
				<div class="col">
					<input type="checkbox" @change="selectAll" id="people-select-all" class="me-2" />

					<span v-if="peopleSelected.length">
						{{ peopleSelected.length }} people selected

						<button
							v-if="peopleSelected.length > 1"
							class="btn btn-sm btn-primary mx-1 my-0"
							data-bs-toggle="modal"
							data-bs-target="#merge-accounts"
						>
							<font-awesome-icon :icon="['fas', 'user-friends']" /> Merge accounts
						</button>

						<button class="btn btn-sm btn-outline-danger mx-1" @click="deletePeople(peopleSelected)">
							<font-awesome-icon :icon="['fas', 'trash']" /> Delete accounts
						</button>
					</span>
					<label v-else for="people-select-all" class="text-muted">Select people to see actions</label>
				</div>
				<div class="col-auto">Showing {{ pag.limit * (pag.page - 1) }}-{{ pag.limit * pag.page }}</div>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr>
								<th></th>
								<th></th>
								<th @click="sortTable('name', 'asc')" class="hover cursor-pointer">
									Name
									<small v-if="sorting.orderBy === 'name'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th>Jurisdiction</th>
								<th @click="sortTable('used_app', 'desc')" class="hover cursor-pointer">
									App
									<small v-if="sorting.orderBy === 'used_app'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('created_at', 'desc')" class="hover cursor-pointer">
									Created
									<small v-if="sorting.orderBy === 'created_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('last_login_at', 'desc')" class="hover cursor-pointer">
									Last login
									<small v-if="sorting.orderBy === 'last_login_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(person, index) in people"
								:key="`${index}-person`"
								:class="{ 'opacity-25': !person.active }"
							>
								<td>
									<input type="checkbox" v-model="peopleSelected" :value="person.id" />
								</td>
								<td>
									<person-avatar
										v-if="person.email"
										:person="person"
										:size="50"
										:preview="false"
										:tooltip="false"
										class="float-start me-2"
									></person-avatar>
								</td>
								<td class="text-truncate">
									<p class="mb-1">
										<router-link :to="`/heygov-admin/people/${person.id}`">
											<span v-if="person.name">{{ person.name }}</span>
											<span v-else class="text-neutral-400">{{ person.anonymous_name }}</span>
										</router-link>
									</p>
									<p class="mb-0">
										{{ person.email }}
										<span
											v-if="person.email && !person.verified"
											class="badge badge-xs bg-danger-lighter text-danger"
											>not verified</span
										>
									</p>
								</td>
								<td>
									<div v-if="person.jurisdictions.length === 1">
										{{ person.jurisdictions[0].name }}

										<span
											class="badge badge-xs"
											:class="allRoles[person.jurisdictions[0].role].class"
											:title="allRoles[person.jurisdictions[0].role].name"
										>
											{{ allRoles[person.jurisdictions[0].role].name }}
										</span>
									</div>
									<span
										v-else-if="person.jurisdictions.length"
										class="badge bg-neutral-100 text-warning-300 cursor-pointer"
										data-bs-toggle="modal"
										data-bs-target="#person-jurisdiction-roles"
										@click="selectedPerson = person"
										>{{ person.jurisdictions.length }} jurisdictions
									</span>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td>
									<template v-if="person.used_app">✅</template>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td>{{ person.created_at | dateLocal }}</td>
								<td>
									<span v-if="person.last_login_at">{{ person.last_login_at | dateLocal }}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.people === 'loading'">
								<td colspan="6" class="text-center py-5">
									<span class="spinner-border" role="status"></span>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div v-if="states.people === 'loaded'" class="row align-items-center">
					<div class="col-auto">Page {{ pag.page }} of results</div>
					<div class="col">
						<nav aria-label="People list navigation">
							<ul class="pagination my-0 justify-content-center">
								<li class="page-item" :class="{ disabled: pag.page <= 1 }">
									<button class="page-link" @click="pag.page--">
										Previous
									</button>
								</li>
								<li class="page-item">
									<button class="page-link" @click="pag.page++">
										Next
									</button>
								</li>
							</ul>
						</nav>
					</div>

					<div class="col-auto">
						<label for="pag-limit" class="me-3 text-neutral-400">Per page</label>
						<div class="d-inline-flex">
							<select id="pag-limit" class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Show person jurisdictions modal-->
		<div class="modal fade" id="person-jurisdiction-roles" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content modal-height" v-if="selectedPerson">
					<div class="modal-header">
						<h5 class="modal-title my-0">
							<span class="bg-warning-lighter">{{ selectedPerson.first_name }}'s</span>
							jurisdictions &#38; roles
						</h5>

						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="card-table">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Jurisdiction</th>
										<th>Role</th>
										<th>Department</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="jurisdiction in selectedPerson.jurisdictions"
										:key="
											`person-${selectedPerson.id}-j-${
												jurisdiction.id
											}-dept-${jurisdiction.department_id || 0}`
										"
									>
										<td>
											{{ jurisdiction.name }}
										</td>
										<td>
											<span class="badge badge-xs" :class="allRoles[jurisdiction.role].class">
												{{ allRoles[jurisdiction.role].name }}
											</span>
										</td>
										<td>
											<span v-if="jurisdiction.department_id"
												>[{{ jurisdiction.department_id }}]</span
											>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Show account to be merged modal  -->
		<div class="modal fade" id="merge-accounts" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div v-if="peopleSelected.length > 1" class="modal-content">
					<form @submit.prevent="mergeAccounts">
						<div class="modal-header">
							<h5 class="modal-title">Merge multiple accounts into one</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Name</th>
										<th v-for="person in peopleSelected" :key="`${person}-name`">
											{{ people.find(p => p.id === person).name }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Email</td>
										<td v-for="person in peopleSelected" :key="`${person}-email`">
											{{ people.find(p => p.id === person).email }}
										</td>
									</tr>
									<tr>
										<td>ID</td>
										<td v-for="person in peopleSelected" :key="`${person}-id`">
											<code>{{ people.find(p => p.id === person).id }} </code>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<th class="bg-light-warning">In case of conflict</th>
										<td v-for="person in peopleSelected" :key="person">
											<label
												><input
													type="radio"
													name="mergeMainAccount"
													:value="person"
													v-model="mergeMainAccount"
												/>

												<span :class="{ 'text-success': person == mergeMainAccount }">
													Preserve these values</span
												></label
											>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
						<div class="modal-footer justify-content-between">
							<button class="btn btn-link text-info">Cancel</button>
							<button class="btn btn-primary">Confirm</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { Modal } from 'bootstrap'
import { debounce } from 'vue-debounce'
import { format, parseISO } from 'date-fns'

import heyGovApi, { handleResponseError } from '@/api.js'
import { chartsCommonData } from '../../actions/charts'

import PersonAvatar from '@/components/PersonAvatar.vue'
import StatsBarChart from '@/components/StatsBarChart.vue'

export default {
	name: 'AdminPeople',
	components: { PersonAvatar, StatsBarChart },
	data() {
		return {
			allRoles: {},
			states: {
				people: 'loading',
				stats: 'loading',
			},

			people: [],
			stats: [],
			filters: {
				jurisdiction_id: this.$route.query.jurisdiction_id || '',
				search: this.$route.query.search || '',
				role: this.$route.query.role || '',
				app: this.$route.query.app || '',
			},
			pag: {
				page: this.$route.query.page || 1,
				limit: localStorage.getItem('admin-people-per-page') || 25,
			},
			sorting: {
				orderBy: localStorage.getItem('admin-people-orderBy') || 'created_at',
				order: localStorage.getItem('admin-people-order') || 'desc',
			},

			selectedPerson: null,
			peopleSelected: [],

			mergeMainAccount: null,
			$modalMerge: null,

			statsChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					total: {
						type: 'linear',
						position: 'right',
					},
					monthNew: {
						type: 'linear',
						position: 'left',
						grid: {
							display: false,
						},
					},
					x: {
						grid: {
							display: false,
						},
					},
				},
			},
			statsChart: {
				labels: [],
				datasets: [
					{
						label: 'Monthly sign-ups',
						data: [],
						fill: false,
						type: 'bar',
						yAxisID: 'monthNew',
						borderWidth: 1,
						borderColor: '#5e81f4',
						backgroundColor: '#C7CFFF',
					},
					{
						label: 'Total',
						data: [],
						type: 'line',
						//yAxisID: 'total',
						borderColor: '#FF7A00',
						backgroundColor: '#FFF6EE',
						pointBorderColor: '#FF7A00',
						pointBackgroundColor: '#FF7A00',
						...chartsCommonData,
					},
				],
			},
		}
	},
	computed: {
		...mapState(['roles']),
		loadPeopleDebounced() {
			return debounce(this.loadPeople, 250)
		},
	},
	mounted() {
		this.$modalMerge = new Modal(document.getElementById('merge-accounts'))
	},
	created() {
		this.allRoles = {
			...this.roles,
			CITIZEN: { name: 'Citizen', class: 'bg-light text-gray' },
			BOT: { name: 'BOT', class: 'bg-neutral-300 text-dark' },
		}

		this.loadPeople()
		this.loadStats()
	},
	methods: {
		loadPeople() {
			this.states.people = 'loading'

			const params = {
				...this.filters,
				...this.sorting,
				limit: this.pag.limit,
				page: this.pag.page,
			}

			heyGovApi.get('super-secret-admin-endpoints/people', { params }).then(({ data }) => {
				this.people = data

				this.states.people = 'loaded'
			})

			this.updatePageUrl()
		},
		loadStats() {
			this.states.stats = 'loading'
			const params = this.filters

			this.statsChart.labels = []
			this.statsChart.datasets[0].data = []
			this.statsChart.datasets[1].data = []

			heyGovApi.get('super-secret-admin-endpoints/people-stats', { params }).then(data => {
				this.stats = data.data

				for (let m in this.stats.byMonth) {
					this.statsChart.labels.push(format(parseISO(`${m}-01`), 'MMM yyyy'))
					this.statsChart.datasets[0].data.push(this.stats.byMonth[m].total)
					this.statsChart.datasets[1].data.push(this.stats.byMonth[m].totalUntil)
				}
			})

			this.states.stats = 'loaded'
		},
		updatePageUrl() {
			let query = {}
			for (const filter in this.filters) {
				if (this.filters[filter]) {
					query[filter] = this.filters[filter]
				}
			}
			if (this.pag.page && this.pag.page != 1) {
				query.page = this.pag.page
			}
			this.$router.replace({ path: `/heygov-admin/people`, query }).catch(() => {})
		},
		sortTable(orderBy, defaultOrder) {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.orderBy = orderBy
		},
		selectAll($event) {
			if ($event.target.checked) {
				this.peopleSelected = this.people.map(p => p.id)
			} else {
				this.peopleSelected = []
			}
		},
		deletePeople(peopleIds) {
			if (confirm('For sure delete so many accounts?')) {
				peopleIds.forEach(id => {
					this.deletePerson(id)
				})
			}
		},
		deletePerson(id) {
			heyGovApi.delete(`/super-secret-admin-endpoints/people/${id}`).then(() => {
				this.people = this.people.filter(p => p.id !== id)
				this.peopleSelected = []
				Vue.toasted.show(`Person deleted from database`)
			}, handleResponseError(`Error deleting person ({error})`))
		},
		mergeAccounts() {
			let peopleIds = this.peopleSelected.filter(p => p !== this.mergeMainAccount)

			heyGovApi.post(`/admin/people/${this.mergeMainAccount}/merge`, { peopleIds }).then(() => {
				peopleIds.forEach(person => {
					this.people = this.people.filter(p => p.id !== person)
				})
				this.peopleSelected = []
				this.loadPeople()

				this.$modalMerge.hide()
				Vue.toasted.show('Accounts are merged')
			})
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.people = []
				this.states.people = 'loading'
				this.loadPeopleDebounced()
			},
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page != 1) {
					this.pag.page = 1
				} else {
					this.people = []
					this.loadPeople()
				}
				localStorage.setItem('admin-people-orderBy', this.sorting.orderBy)
				localStorage.setItem('admin-people-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.people = []
			this.loadPeople()
		},
		'pag.limit'() {
			this.people = []
			this.loadPeople()
			localStorage.setItem('admin-people-per-page', this.pag.limit)
		},
	},
}
</script>
