<template>
	<div class="payments-page">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Payments List</h2>
			</div>
			<div class="col-auto">
				<a :href="exportUrl" class="btn btn-sm btn-outline-info">Export CSV</a>
			</div>
		</div>

		<div class="bar-filters border rounded bg-white p-1 mb-2">
			<div class="row align-items-center justify-content-around g-2">
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.jurisdiction_id">
						<option value="">All jurisdiction</option>
						<option value="live">Real jurisdictions</option>
						<option
							v-for="jurisdiction in jurisdictions"
							:key="`${jurisdiction.id}-munipality`"
							:value="jurisdiction.id"
							>{{ jurisdiction.name }}</option
						>
					</select>
				</div>

				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.platform">
						<option value="">Platform</option>
						<option value="heygov">HeyGov</option>
						<option value="stripe">Stripe</option>
						<option value="justifi">JustiFi</option>
						<option value="paygov">PayGov</option>
					</select>
				</div>

				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.status">
						<option value="">Status</option>
						<option value="succeeded">Paid</option>
						<option value="requires_capture">Authorized</option>
						<option value="requires_payment_method">Waiting</option>
						<option value="canceled">Canceled</option>
					</select>
				</div>

				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.payment_method">
						<option value="">Payment method</option>
						<option value="card">Card - online</option>
						<option value="card_present">Card - in person</option>
						<option value="check">Check</option>
						<option value="cash">Cash</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.date_range">
						<option value="30-days">Last 30 days</option>
						<option value="3-months">Last 3 months</option>
						<option value="12-months">Last 12 months</option>
						<option value="-30-days">Older than 30 days</option>
						<option value="-90-days">Older than 90 days</option>
					</select>
				</div>
			</div>
		</div>

		<div class="card">
			<div class="card-body text-center pt-3 pb-2">
				<div class="row">
					<div class="col">
						<h5 v-if="stats" class="display-6 my-0">
							{{ stats.totalAmountLifetime | currency }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
						<p class="mb-0 text-muted">Lifetime volume</p>
					</div>
					<div class="col text-dark">
						<h5 v-if="states.stats === 'idle'" class="display-6 my-0">
							{{ stats.totalAmount | currency }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
						<p class="mb-0 text-muted">Last {{ filters.date_range.replace('-', ' ') }}</p>
					</div>
					<div class="col">
						<h5 v-if="states.stats === 'idle'" class="display-6 my-0">{{ stats.totalPayments }}</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
						<p class="mb-0 text-muted">Payments {{ filters.date_range.replace('-', ' ') }}</p>
					</div>
					<div class="col">
						<h5 v-if="states.stats === 'idle'" class="display-6 my-0">
							{{ stats.paymentsPerDay.toFixed(1) }}
						</h5>
						<h5 v-else class="display-6 text-muted my-0">-</h5>
						<p class="mb-0 text-muted">Payments/day</p>
					</div>
				</div>
			</div>
		</div>

		<div v-if="states.stats === 'idle'" class="row align-items-center my-3">
			<div class="col">
				<stats-line-chart :data="statsChart" :options="statsChartOptions" :height="250"></stats-line-chart>
			</div>
			<div class="col-auto">
				<stats-pie-chart
					:data="jurisdictionsChart"
					:options="jurisdictionChartOptions"
					:height="200"
				></stats-pie-chart>

				<div class="btn-group" role="group" aria-label="Payment breakdown by payments or amount">
					<button
						class="btn btn-sm"
						:class="states.jurisdictionsChartValue === 'count' ? 'fw-bold' : 'text-neutral-400'"
						@click="drawJurisidctionsChart('count')"
					>
						By payments
					</button>
					<button
						class="btn btn-sm"
						:class="states.jurisdictionsChartValue === 'amount' ? 'fw-bold' : 'text-neutral-400'"
						@click="drawJurisidctionsChart('amount')"
					>
						By amounts
					</button>
				</div>
			</div>
		</div>
		<div v-else class="text-center bg-light rounded-1 py-5 my-3" style="min-height: 250px">
			<p>Generating fresh stats for you</p>
			<span class="spinner-border" role="status"></span>
		</div>

		<div class="card rounded-1">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>ID</th>
								<th><small>~</small></th>
								<th>Muni</th>
								<th @click="sortTable('amount', 'desc')" class="hover cursor-pointer">
									Amount
									<small v-if="sorting.orderBy === 'amount'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('fee', 'desc')" class="hover cursor-pointer">
									Fee
									<small v-if="sorting.orderBy === 'fee'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('created_at', 'desc')" class="hover cursor-pointer">
									Date
									<small v-if="sorting.orderBy === 'created_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th>Status</th>
								<th>With</th>
								<th>Person</th>
								<th>Description</th>
								<th>Device</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="payment in payments" :key="payment.uuid">
								<td>
									<small class="text-muted" :title="payment.uuid">{{ payment.id }}</small>
								</td>
								<td>
									<font-awesome-icon
										v-if="paymentPlatforms[payment.platform].faIcon"
										:icon="paymentPlatforms[payment.platform].faIcon"
										:style="{
											color: paymentPlatforms[payment.platform].brandColor,
										}"
										:title="paymentPlatforms[payment.platform].name"
										class="me-1"
									/>
									<img
										v-else-if="paymentPlatforms[payment.platform].logo"
										:src="paymentPlatforms[payment.platform].logo"
										height="20"
										:alt="paymentPlatforms[payment.platform].name"
										:title="paymentPlatforms[payment.platform].name"
									/>
									<pre v-else>{{ paymentPlatforms[payment.platform] }}</pre>
								</td>
								<td>
									{{ truncateString(payment.jurisdiction_name, 20) }}
								</td>
								<td>{{ payment.amount | currency }}</td>
								<td>
									<span :class="{ 'text-neutral-300': !payment.fee }">{{
										payment.fee | currency
									}}</span>
								</td>
								<td>{{ payment.created_at | dateLocal }}</td>
								<td>
									<payment-status v-if="payment.status" :payment="payment" />
								</td>
								<td>
									<payment-method
										v-if="payment.payment_method"
										:payment-method="JSON.parse(payment.payment_method)"
									/>
								</td>
								<td>
									<router-link
										v-if="payment.person"
										:to="`/heygov-admin/people/${payment.person.id}`"
										>{{ payment.person.shortNameOrEmail }}</router-link
									>
									<span v-else>{{ payment.email_or_phone }}</span>
								</td>
								<td>
									<font-awesome-icon
										v-if="payment.source === 'link'"
										:icon="['fas', 'comment-dollar']"
										class="me-1"
									/>
									<font-awesome-icon
										v-else-if="payment.source === 'quick-pay'"
										icon="fa-solid fa-forward"
										class="me-1"
									/>
									<font-awesome-icon
										v-else-if="payment.source === 'card-reader'"
										:icon="['far', 'credit-card']"
										class="me-1"
									/>

									<span v-if="payment.source.includes('_')"
										><code>{{
											payment.source.replace('form-requests/', '').replace('venue-bookings/', '')
										}}</code></span
									>
									<span class="ms-2">{{ payment.description }}</span>
								</td>
								<td>
									<small
										v-if="payment.payment_method && payment.payment_method.includes('card_present')"
									>
										<font-awesome-icon :icon="['fas', 'cash-register']" class="me-1" />
										<code>card reader</code>
									</small>
									<small
										v-else-if="payment.from_ua.device && payment.from_ua.device.type === 'mobile'"
									>
										📱
										<code>{{ payment.from_platform }}</code>
										{{ [payment.from_ua.device.vendor, payment.from_ua.device.model].join(' ') }}
									</small>
									<small v-else>
										🖥
										{{
											[
												payment.from_ua.os ? payment.from_ua.os.name : null,
												payment.from_ua.browser ? payment.from_ua.browser.name : null,
											]
												.filter(Boolean)
												.join(' ')
										}}
									</small>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.payments === 'loading'">
								<td colspan="9">
									<p class="text-center">loading..</p>
								</td>
							</tr>
							<tr v-else-if="states.payments === 'idle' && payments.length" class="bg-light text-muted">
								<th></th>
								<th></th>
								<th>
									<small>{{ sumAmount | currency }}</small>
								</th>
								<th>
									<small>{{ sumFee | currency }}</small>
								</th>
								<th></th>
								<th></th>
								<th></th>
								<th>
									<small v-if="uniq(payments.map(p => p.person_id)).length > 1"
										>{{ uniq(payments.map(p => p.person_id)).length }} people</small
									>
								</th>
								<th></th>
								<th></th>
							</tr>
							<tr v-else-if="states.payments === 'idle' && !payments.length">
								<td colspan="10" class="text-center py-3">
									<p v-if="hasActiveFilters" class="lead">
										No payments found. Try changing the filters
									</p>
									<p v-else class="lead">Awaiting payments</p>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div class="row align-items-center">
					<div class="col">
						<span class="me-3 text-muted">Per page</span>
						<div class="d-inline-flex ">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div v-if="pag.total" class="col" @dblclick="states.delete = true">
						Showing {{ pag.limit * (pag.page - 1) }}-{{ pag.limit * pag.page }} of {{ pag.total }}
					</div>
					<div v-if="filters.status === 'requires_payment_method' || states.delete" class="col-auto">
						<button class="btn btn-sm btn-outline-danger" @click="deleteOldPaymentsDrafts">
							🗑️ Delete payment drafts
						</button>
					</div>
					<div class="col-auto">
						<nav aria-label="Page navigation example" v-if="pag.pages > 1">
							<ul class="pagination my-0">
								<!-- TODO add these buttons when there are > 20 pages -->

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>

								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a
										v-if="Math.abs(pag.page - p) < 5 || pag.page == pag.pages - 1 || p == 0"
										class="page-link"
										@click="pag.page = p"
									>
										{{ p }}
									</a>
								</li>

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { uniq } from 'lodash'
import { mapState } from 'vuex'
import { format, parseISO } from 'date-fns'
import Vue from 'vue'
//import randomColor from 'randomcolor'

import heyGovApi from '@/api.js'
import { numberToCurrency, truncateString } from '@/utils'
import { paymentPlatforms, paymentStatuses } from '@/actions/payments.js'

import PaymentStatus from '@/components/payments/PaymentStatus.vue'
import PaymentMethod from '@/components/payments/PaymentMethod.vue'
import StatsLineChart from '@/components/StatsLineChart.vue'
import StatsPieChart from '@/components/StatsPieChart.vue'
import { chartsCommonData } from '../../actions/charts'

export default {
	components: { PaymentStatus, PaymentMethod, StatsLineChart, StatsPieChart },
	props: { jurisdictions: Array },
	data() {
		return {
			payments: [],
			stats: null,
			states: {
				payments: 'loading',
				stats: 'loading',
				export: 'idle',
				jurisdictionsChartValue: 'count',
				delete: false,
			},
			//colors: [],
			paymentStatuses,
			paymentPlatforms,
			filters: {
				jurisdiction_id: this.$route.query.jurisdiction_id || 'live',
				status: this.$route.query.status || 'succeeded',
				payment_method: this.$route.query.payment_method || '',
				platform: this.$route.query.platform || '',
				date_range: this.$route.query.date_range || localStorage.getItem('admin-date-range') || '12-months',
			},
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				limit: localStorage.getItem('admin-payments-per-page') || 25,
			},
			sorting: {
				orderBy: localStorage.getItem('admin-payments-orderBy') || 'created_at',
				order: localStorage.getItem('admin-payments-order') || 'desc',
			},
			totalAmountLifetime: null,
			totalAmount: null,
			totalPayments: null,
			paymentsPerDay: null,
			statsChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					amount: {
						type: 'linear',
						position: 'left',
						min: 0,
						grid: {
							display: true,
						},
						ticks: {
							callback: function(value) {
								return numberToCurrency(value).replace('.00', '')
							},
						},
					},
					payments: {
						type: 'linear',
						position: 'right',
						min: 0,
						ticks: {
							stepSize: 10,
						},
						grid: {
							display: false,
						},
					},
					platform: {
						type: 'linear',
						position: 'right',
						grid: {
							display: false,
						},
						display: false,
						stacked: true,
						min: 0,
						ticks: {
							stepSize: 10,
						},
					},
					x: {
						grid: {
							display: false,
						},
					},
				},
			},
			statsChart: {
				labels: [],
				datasets: [
					{
						label: 'Payments',
						data: [],
						yAxisID: 'payments',
						borderColor: '#5e81f4',
						backgroundColor: '#C7CFFF',
						pointBorderColor: 'rgb(94, 129, 244)',
						pointBackgroundColor: 'rgb(94, 129, 244, 0.5)',
						...chartsCommonData,
					},
					{
						label: 'Amount',
						data: [],
						yAxisID: 'amount',
						borderColor: 'rgb(2, 206, 167)',
						backgroundColor: 'rgba(240, 255, 252, 0.3)',
						pointBorderColor: 'rgb(2, 206, 167)',
						pointBackgroundColor: 'rgb(2, 206, 167, 0.5)',
						...chartsCommonData,
						tooltip: {
							callbacks: {
								label: function(ctx) {
									return `${ctx.dataset.label}: ${numberToCurrency(ctx.raw)}`
								},
							},
						},
					},
					{
						label: 'Web',
						yAxisID: 'platform',
						data: [],
						backgroundColor: 'rgba(255,192,203,0.3)', // pink
						pointRadius: 0,
						pointHoverRadius: 0,
						showLine: false,
						fill: 'stack',
					},
					{
						label: 'iOS',
						yAxisID: 'platform',
						data: [],
						backgroundColor: 'rgba(178,132,190,0.3)', // purple
						pointRadius: 0,
						pointHoverRadius: 0,
						showLine: false,
						fill: 'stack',
					},
					{
						label: 'Android',
						yAxisID: 'platform',
						data: [],
						backgroundColor: 'rgba(255,153,0,0.3)', // orange
						pointRadius: 0,
						pointHoverRadius: 0,
						showLine: false,
						fill: 'stack',
					},
					{
						label: 'Card Reader',
						yAxisID: 'platform',
						data: [],
						backgroundColor: 'rgba(255,220,0, 0.3)', // orange
						pointRadius: 0,
						pointHoverRadius: 0,
						showLine: false,
						fill: 'stack',
					},
					{
						label: 'Payments per Day ',
						yAxisID: 'payments',
						data: [],
						borderColor: 'rgb(76,187,23)',
						backgroundColor: 'rgba(76,187,23,0.4)',
						pointBorderColor: 'rgba(76,187,23)',
						pointBackgroundColor: 'rgb(76,187,23)',
						...chartsCommonData,
					},
				],
			},
			jurisdictionsChart: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [
							'rgb(109, 249, 177)',
							'rgb(52, 55, 226)',
							'rgb(21, 129, 237)',
							'rgb(46, 153, 160)',
							'rgb(4, 201, 109)',
							'rgb(255, 168, 30)',
							'rgb(46, 79, 201)',
							'rgb(255, 50, 64)',
							'rgb(62, 37, 221)',
							'rgb(82, 229, 143)',
							'rgb(198, 30, 216)',
							'rgb(43, 175, 70)',
							'rgb(206, 255, 102)',
							'rgb(88, 132, 0)',
							'rgb(142, 11, 22)',
							'rgb(203, 97, 244)',
						],
						borderWidth: 1,
						hoverOffset: 2,
					},
				],
			},
			jurisdictionChartOptions: {
				cutout: 40,
				maintainAspectRatio: false,
				responsive: true,
				plugins: {
					legend: {
						display: false,
					},
					title: {
						display: true,
						text: 'by Jurisdiction',
						font: 'Agradir',
					},
				},
				scales: {
					amount: {
						display: false,
						tooltip: {
							callbacks: {
								label: function(ctx) {
									return `${ctx.dataset.label}: ${numberToCurrency(ctx.raw)}`
								},
							},
						},
					},
				},
			},
		}
	},
	computed: {
		...mapState(['apiUrl']),
		hasActiveFilters() {
			return Object.values(this.filters).some(filter => filter)
		},
		sumAmount() {
			return this.payments.map(p => p.amount).reduce((a, b) => a + b, 0)
		},
		sumFee() {
			return this.payments.map(p => p.fee).reduce((a, b) => a + b, 0)
		},
		exportUrl() {
			const params = new URLSearchParams(this.filters)
			return `${this.apiUrl}admin/payments-export?${params.toString()}`
		},
	},
	created() {
		this.loadPayments()
	},
	methods: {
		uniq,
		truncateString,

		loadPayments() {
			this.states.payments = 'loading'

			const params = {
				...this.filters,
				...this.sorting,
				limit: this.pag.limit,
				page: this.pag.page,
			}

			heyGovApi.get('admin/payments?expand=person', { params }).then(data => {
				this.payments = data.data
				this.pag.total = data.headers['x-total']
				this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)
				this.states.payments = 'idle'
			})

			this.updatePageUrl()

			if (this.filters.status !== 'requires_payment_method') {
				this.loadStats()
			}
		},
		loadStats() {
			this.states.stats = 'loading'

			// empty state for stats & chart
			this.statsChart.labels = []
			this.statsChart.datasets[0].data = []
			this.statsChart.datasets[1].data = []
			this.statsChart.datasets[2].data = []
			this.statsChart.datasets[3].data = []
			this.statsChart.datasets[4].data = []
			this.statsChart.datasets[5].data = []
			this.statsChart.datasets[6].data = []
			this.jurisdictionsChart.labels = []
			this.jurisdictionsChart.datasets[0].data = []
			this.totalAmount = null
			this.totalPayments = null
			this.paymentsPerDay = null

			heyGovApi.get('admin/payments-stats', { params: this.filters }).then(
				({ data }) => {
					this.stats = data
					this.states.stats = 'idle'

					if (this.filters.date_range.includes('months') || this.filters.date_range.startsWith('-')) {
						for (let m in this.stats.byMonth) {
							this.statsChart.labels.push(format(parseISO(`${m}-01`), 'MMM yyyy'))
							this.statsChart.datasets[0].data.push(this.stats.byMonth[m].count)
							this.statsChart.datasets[1].data.push(this.stats.byMonth[m].amount)
							this.statsChart.datasets[2].data.push(this.stats.byMonth[m].web)
							this.statsChart.datasets[3].data.push(this.stats.byMonth[m].ios)
							this.statsChart.datasets[4].data.push(this.stats.byMonth[m].android)
							this.statsChart.datasets[5].data.push(this.stats.byMonth[m].card_reader)
							this.statsChart.datasets[6].data.push(this.stats.byMonth[m].perDay)
						}
					} else {
						for (let c in this.stats.byDay) {
							this.statsChart.labels.push(format(parseISO(`${c}`), 'MMM dd'))
							this.statsChart.datasets[0].data.push(this.stats.byDay[c].count)
							this.statsChart.datasets[1].data.push(this.stats.byDay[c].amount)
							this.statsChart.datasets[2].data.push(this.stats.byDay[c].web)
							this.statsChart.datasets[3].data.push(this.stats.byDay[c].ios)
							this.statsChart.datasets[4].data.push(this.stats.byDay[c].android)
							this.statsChart.datasets[5].data.push(this.stats.byDay[c].card_reader)
						}
					}

					// fill jurisdictions chart
					for (let j in this.stats.byJurisdiction) {
						this.jurisdictionsChart.labels.push(
							this.jurisdictions.find(jurisdiction => jurisdiction.id == j)?.name.substring(0, 25) || '??'
						)
					}
					this.drawJurisidctionsChart('count')
				},
				error => {
					Vue.toasted.error(error.response?.data?.message || `Couldn't load stats (${error.message})`)
				}
			)
		},

		drawJurisidctionsChart(by) {
			this.states.jurisdictionsChartValue = by
			this.jurisdictionsChart.datasets[0].data = []

			for (let j in this.stats.byJurisdiction) {
				this.jurisdictionsChart.datasets[0].data.push(
					this.stats.byJurisdiction[j][this.states.jurisdictionsChartValue]
				)
			}
		},

		updatePageUrl() {
			let query = {}
			for (const filter in this.filters) {
				if (this.filters[filter]) {
					query[filter] = this.filters[filter]
				}
			}
			if (this.pag.page && this.pag.page != 1) {
				query.page = this.pag.page
			}
			this.$router.replace({ path: `/heygov-admin/payments-list`, query }).catch(() => {})
		},
		sortTable(orderBy, defaultOrder) {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}
			this.sorting.orderBy = orderBy
		},
		deleteOldPaymentsDrafts() {
			if (confirm('For sure delete these payments?')) {
				heyGovApi.get(`admin/cleanup-payments`, { params: this.filters }).then(({ data }) => {
					this.filters = {
						...this.filters,
						status: 'succeeded',
						date_range: localStorage.getItem('admin-date-range') || '12-months',
					}

					Vue.toasted.show(`${data} payments were deleted`)
				})
			}
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.pag.page = 1
				this.payments = []
				this.loadPayments()

				if (!this.filters.date_range.startsWith('-')) {
					localStorage.setItem('admin-date-range', this.filters.date_range)
				}
			},
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page !== 1) {
					this.pag.page = 1
				} else {
					// otherwise reload data now
					this.payments = []
					this.loadPayments()
				}
				localStorage.setItem('admin-payments-orderBy', this.sorting.orderBy)
				localStorage.setItem('admin-payments-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.payments = []
			this.loadPayments()
		},
		'pag.limit'() {
			this.payments = []
			this.loadPayments()
			localStorage.setItem('admin-payments-per-page', this.pag.limit)
		},
	},
}
</script>
