<template>
	<div class="admin-forms-page">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Forms</h2>
			</div>
		</div>
		<div class="bar-filters border rounded bg-white p-1 mb-2">
			<div class="row align-items-center justify-content-around g-2">
				<div class="col">
					<input
						type="search"
						class="form-control form-control-sm"
						placeholder="Search 🔎"
						v-model="filters.search"
					/>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.jurisdiction_id">
						<option value="">All jurisdiction</option>
						<option value="live">Real jurisdictions</option>
						<option disabled>Jurisdictions list coming soon</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.status">
						<option value="">Status</option>
						<option value="public">Public</option>
						<option value="draft">Draft</option>
						<option value="archived">Archived</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.is_template">
						<option value="">Template</option>
						<option value="1">Yes</option>
						<option value="0">No</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.auto_approve">
						<option value="">Approval</option>
						<option value="1">Auto</option>
						<option value="0">Manual</option>
					</select>
				</div>
				<!-- <div class="col">
					<select class="form-select form-select-sm" v-model="filters.date_range">
						<option value="30-days">Last 30 days</option>
						<option value="3-months">Last 3 months</option>
						<option value="12-months">Last year</option>
					</select>
				</div> -->
				<div class="col-auto">
					<div class="dropdown">
						<button
							class="btn btn-sm px-2"
							type="button"
							data-bs-toggle="dropdown"
							title="Customize table columns"
						>
							<font-awesome-icon :icon="['fas', 'columns']" />
						</button>
						<ul class="dropdown-menu">
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="jurisdiction" />
									Jurisdiction</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="form_name" /> Form</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="template" /> Template</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="submissions" />
									Submissions</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="expiry_date" /> Expiry</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="status" /> Status</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="published_at" />
									Published</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="auto_approve" />
									AutoAproval</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="payment" /> Payment</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="created_at" /> Created</label
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!-- <div v-if="states.stats === 'idle'" class="row align-items-center my-3">
			<div class="col">
				<stats-line-chart :data="statsChart" :options="statsChartOptions" :height="250"></stats-line-chart>
			</div>
			<div class="col-auto">
				<stats-pie-chart
					:data="jurisdictionsChart"
					:options="jurisdictionChartOptions"
					:height="220"
				></stats-pie-chart>
			</div>
		</div>
		<div v-else class="text-center bg-light rounded-1 py-5 my-3" style="min-height: 250px">
			<p>Generating fresh stats for you</p>
			<span class="spinner-border" role="status"></span>
		</div> -->

		<!-- Forms table -->

		<div class="card">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr>
								<th v-if="tableColumns.includes('jurisdiction')">Jurisdiction</th>
								<th @click="sortTable('name', 'asc', sorting)" class="hover cursor-pointer">
									Form
									<small v-if="sorting.orderBy === 'name'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th
									v-if="tableColumns.includes('submissions')"
									@click="sortTable('requests_count', 'desc', sorting)"
									class="hover cursor-pointer"
								>
									Submissions
									<small v-if="sorting.orderBy === 'requests_count'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th
									v-if="tableColumns.includes('template')"
									@click="sortTable('is_template', 'desc', sorting)"
									class="hover cursor-pointer"
								>
									Template
									<small v-if="sorting.orderBy === 'is_template'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th v-if="tableColumns.includes('expiry_date')">Expiry</th>
								<th
									v-if="tableColumns.includes('status')"
									@click="sortTable('status', 'desc', sorting)"
									class="hover cursor-pointer"
								>
									<span>
										Status
										<small v-if="sorting.orderBy === 'status'">{{
											sorting.order === 'asc' ? '▲' : '▼'
										}}</small>
									</span>
								</th>
								<th
									v-if="tableColumns.includes('published_at')"
									@click="sortTable('published_at', 'desc', sorting)"
									class="hover cursor-pointer"
								>
									<span>
										Published
										<small v-if="sorting.orderBy === 'published_at'">{{
											sorting.order === 'asc' ? '▲' : '▼'
										}}</small>
									</span>
								</th>
								<th v-if="tableColumns.includes('auto_approve')">Approval</th>
								<th v-if="tableColumns.includes('payment')">Payment</th>
								<th
									v-if="tableColumns.includes('created_at')"
									@click="sortTable('created_at', 'desc', sorting)"
									class="hover cursor-pointer"
								>
									<span>
										Created
										<small v-if="sorting.orderBy === 'created_at'">{{
											sorting.order === 'asc' ? '▲' : '▼'
										}}</small>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="form in forms" :key="`heygov-admin-${form.id}`">
								<td v-if="tableColumns.includes('jurisdiction')">
									{{ form.jurisdiction_name }}
								</td>
								<td v-if="tableColumns.includes('form_name')">
									<router-link :to="`/${form.jurisdiction_slug}/forms/${form.slug}`">{{
										form.name
									}}</router-link>

									<span v-if="form.application_prefix" class="badge bg-neutral-100 text-dark ms-2"
										>{{ form.application_prefix }}
									</span>
									<span v-else class="badge bg-danger-50 text-danger-300 ms-2">-</span>
								</td>
								<td v-if="tableColumns.includes('submissions')">
									<span class="badge bg-light text-dark">{{ form.requests_count }}</span>
								</td>
								<td v-if="tableColumns.includes('template')">
									<small
										class="text-neutral-300 cursor-pointer"
										@click="updateForm(form, { is_template: !form.is_template })"
										>{{ form.is_template ? '✅' : 'X' }}
									</small>
								</td>
								<td v-if="tableColumns.includes('expiry_date')">
									{{ form.expiry_date }}
								</td>
								<td v-if="tableColumns.includes('status')">
									<span class="badge" :class="formStatuses[form.status].class">
										{{ formStatuses[form.status].name }}
									</span>
								</td>
								<td v-if="tableColumns.includes('published_at')">
									{{ form.published_at ? form.published_at : '' | dateLocal }}
								</td>
								<td v-if="tableColumns.includes('auto_approve')">
									<span v-if="form.auto_approve" class="badge bg-success-50 text-success-300"
										>⚡️ Auto</span
									>
									<span v-else class="badge bg-neutral-50 text-neutral-300">Manual</span>
								</td>
								<td v-if="tableColumns.includes('payment')">
									<span
										class="badge bg-neutral-50 cursor-pointer"
										:class="form.price ? 'text-primary-200' : 'text-neutral-200'"
										@click="updateFormPrice(form)"
										>{{ form.price || '-' }}</span
									>
								</td>
								<td v-if="tableColumns.includes('created_at')">
									{{ form.created_at | dateLocal }}
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.forms === 'loading'">
								<td colspan="9" class="text-center py-3">
									<div class="spinner-border spinner-border-sm" role="state"></div>
								</td>
							</tr>
							<tr v-else-if="states.forms === 'idle' && !forms.length">
								<td colspan="9" class="text-center py-3">
									<span class="text-muted fw-bolder">There are no forms yet 🤷</span>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
				<!-- Pagination -->
				<div class="row align-items-center">
					<div class="col">
						<span class="me-3 text-muted">Per page</span>
						<div class="d-inline-flex ">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div v-if="pag.total" class="col">
						Showing {{ pag.limit * (pag.page - 1) }}-{{ pag.limit * pag.page }} of {{ pag.total }}
					</div>
					<div class="col-auto">
						<nav aria-label="Page navigation example" v-if="pag.pages > 1">
							<ul class="pagination my-0">
								<!-- TODO add these buttons when there are > 20 pages -->

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>

								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a
										v-if="Math.abs(pag.page - p) < 5 || pag.page == pag.pages - 1 || p == 0"
										class="page-link"
										@click="pag.page = p"
									>
										{{ p }}
									</a>
								</li>

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'
// import { format, parseISO } from 'date-fns'

import { truncateString, setting, handleResponseError } from '@/utils.js'
//import { chartsCommonData, jChart, jChartOptions } from '../../actions/charts'
import { sortTable, updatePageUrl } from '@/actions/tables'
import { formStatuses } from '@/actions/forms'

//import StatsLineChart from '@/components/StatsLineChart.vue'
// import StatsPieChart from '@/components/StatsPieChart.vue'

export default {
	//components: { StatsLineChart, StatsPieChart },
	data() {
		return {
			forms: [],
			states: {
				forms: 'idle',
				//stats: 'idle',
				delete: false,
			},
			formStatuses,
			filters: {
				jurisdiction_id: this.$route.query.jurisdiction_id || 'live',
				status: this.$route.query.status || '',
				is_template: this.$route.query.is_template || '',
				auto_approve: this.$route.query.auto_approve || '',
				search: this.$route.query.search || '',
				//date_range: this.$route.query.date_range || localStorage.getItem('admin-date-range') || '12-months',
			},
			sorting: {
				orderBy: localStorage.getItem('admin-forms-orderBy') || 'created_at',
				order: localStorage.getItem('admin-forms-order') || 'desc',
			},
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				limit: localStorage.getItem('admin-forms-per-page') || 50,
			},
			tableColumns: [
				'juisdiction',
				'form_name',
				'template',
				'submissions',
				'exiry_date',
				'status',
				'published_at',
				'auto_approve',
				'payment',
				'created_at',
			],
			/* 	statsChart: {
				labels: [],
				datasets: [
					{
						label: 'Public',
						data: [],
						borderColor: 'rgba(75, 103, 195, 0.8)',
						backgroundColor: '#b9c4ff',
						pointBorderColor: 'rgba(75, 103, 195, 0.8)',
						pointBackgroundColor: 'rgba(75, 103, 195, 0.8)',
						...chartsCommonData,
					},
					{
						label: 'Draft',
						data: [],
						borderColor: 'rgba(52, 58, 64, 0.8)',
						backgroundColor: '#898c90',
						pointBorderColor: 'rgba(52, 58, 64, 0.8)',
						pointBackgroundColor: 'rgba(52, 58, 64, 0.8)',
						...chartsCommonData,
					},
					{
						label: 'Archived',
						data: [],
						borderColor: 'rgba(232, 51, 51, 0.8)',
						backgroundColor: '#f9c7c7',
						pointBorderColor: 'rgba(232, 51, 51, 0.8)',
						pointBackgroundColor: 'rgba(232, 51, 51, 0.8)',
						...chartsCommonData,
					},
				],
			},
			statsChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						grid: {
							display: false,
						},
						min: 0,
					},
				},
			},
			jurisdictionsChart: {
				...jChart,
			},
			jurisdictionChartOptions: {
				...jChartOptions,
			}, */
		}
	},
	created() {
		this.loadForms()

		// load table columns preference from local storage
		setting('admin-forms-table-columns').then(columns => {
			if (columns) {
				this.tableColumns = columns
			}
		})
	},
	methods: {
		truncateString,
		sortTable,
		updatePageUrl,

		loadForms() {
			this.states.forms = 'loading'

			const params = { ...this.filters, ...this.sorting, limit: this.pag.limit, page: this.pag.page }

			heyGovApi.get('super-secret-admin-endpoints/forms', { params }).then(data => {
				this.forms = data.data
				this.pag.total = Number(data.headers['x-total'])
				this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)
				this.states.forms = 'idle'
			})
			//this.loadStats()
			this.updatePageUrl(this.pag, this.filters, this.$router, 'forms')
		},

		/* loadStats() {
			this.states.stats = 'loading'
			this.statsChart.labels = []
			this.jurisdictionsChart.labels = []
			this.statsChart.datasets[0].data = []
			this.statsChart.datasets[1].data = []
			this.statsChart.datasets[2].data = []
			this.jurisdictionsChart.datasets[0].data = []
			const params = { ...this.filters }
			heyGovApi.get('admin/forms-stats', { params }).then(data => {
				this.stats = data.data

				if (this.filters.date_range.includes('months')) {
					for (let m in this.stats.byMonth) {
						this.statsChart.labels.push(format(parseISO(`${m}-01`), 'MMM yyyy'))
						this.statsChart.datasets[0].data.push(this.stats.byMonth[m].public)
						this.statsChart.datasets[1].data.push(this.stats.byMonth[m].draft)
						this.statsChart.datasets[2].data.push(this.stats.byMonth[m].archived)
					}
				} else {
					for (let c in this.stats.byDay) {
						this.statsChart.labels.push(format(parseISO(`${c}`), 'MMM dd'))
						this.statsChart.datasets[0].data.push(this.stats.byDay[c].public)
						this.statsChart.datasets[1].data.push(this.stats.byDay[c].draft)
						this.statsChart.datasets[2].data.push(this.stats.byDay[c].archived)
					}
				}

				for (let j in this.stats.byJurisdiction) {
					this.jurisdictionsChart.datasets[0].data.push(this.stats.byJurisdiction[j].count)
					if (this.jurisdictions.length > 0) {
						this.jurisdictionsChart.labels.push(
							this.jurisdictions.find(jurisdiction => jurisdiction.id == j).name.substring(0, 25)
						)
					}
				}

				this.states.stats = 'idle'
			})
		}, */
		updateForm(form, data) {
			heyGovApi.put(`/${form.jurisdiction_id}/forms/${form.slug}`, data).then(() => {
				for (const field in data) {
					form[field] = data[field]
				}
				Vue.toasted.success(`"${form.name}" is updated`)
			}, handleResponseError('Could not update form ({error})'))
		},
		updateFormPrice(form) {
			const price = prompt('Enter new price', form.price || '')
			this.updateForm(form, { price })
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.forms = []
				this.loadForms()
			},
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page !== 1) {
					this.pag.page = 1
				} else {
					// otherwise reload data now
					this.forms = []
					this.loadForms()
				}
				localStorage.setItem('admin-forms-orderBy', this.sorting.orderBy)
				localStorage.setItem('admin-forms-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.forms = []
			this.loadForms()
		},
		'pag.limit'() {
			this.forms = []
			this.loadForms()
			localStorage.setItem('admin-forms-per-page', this.pag.limit)
		},
		tableColumns() {
			setting('admin-forms-table-columns', this.tableColumns)
		},
	},
}
</script>
