<template>
	<div v-if="jurisdiction">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">{{ jurisdiction.name }}</h2>
			</div>
			<div class="col-auto">
				<router-link :to="`/${jurisdiction.slug}`" class="btn btn-sm btn-primary ms-2"
					>View Dashboard</router-link
				>
				<a :href="jurisdiction.website" target="_blank" class="btn btn-sm btn-outline-dark ms-2"
					>Visit website ❐</a
				>
				<a
					v-if="jurisdiction.mc_municipality_id"
					:href="`https://mc2.townweb.com/municipalities/edit/${jurisdiction.mc_municipality_id}`"
					target="_blank"
					class="btn btn-sm btn-outline-dark ms-2"
					>View in MC2 ❐
				</a>
				<router-link
					:to="`/${jurisdiction.slug}/onboarding/${jurisdiction.uuid}`"
					class="btn btn-sm btn-outline-primary ms-2"
					>👋 Onboarding</router-link
				>
				<a
					:href="`${apiUrl + jurisdiction.slug}/stats/monthly-email`"
					target="_blank"
					class="btn btn-sm btn-outline-primary ms-2"
					>📈 Monthly stats</a
				>
			</div>
		</div>

		<div class="bg-warning-lighter rounded-1 border border-dashed text-center p-5 mb-3">
			[{{ jurisdiction.type }} activity chart, soon]
		</div>

		<div class="card mb-5">
			<div class="card-body">
				<form @submit.prevent="updateJurisdiction(jurisdiction)">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group mb-3">
								<label class="form-label" for="jurisdiction-name">Name </label>
								<input class="form-control" id="jurisdiction-name" v-model="jurisdiction.name" />
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="juisdiction-website">Website</label>
								<input
									type="url"
									class="form-control form-control-sm"
									id="juisdiction-website"
									v-model="jurisdiction.website"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="juisdiction-email">Email</label>
								<input
									type="email"
									class="form-control form-control-sm"
									id="juisdiction-email"
									v-model="jurisdiction.email"
								/>
							</div>

							<div class="row">
								<div class="form-group col-6 mb-3">
									<label class="form-label" for="population">Population</label>
									<input
										type="number"
										class="form-control form-control-sm"
										id="population"
										v-model="jurisdiction.population_count"
										placeholder="Population count"
									/>
								</div>

								<div class="form-group col-6 mb-3">
									<label class="form-label" for="households">Households</label>
									<input
										type="number"
										class="form-control form-control-sm"
										id="households"
										v-model="jurisdiction.households_count"
										placeholder="Households count"
									/>
								</div>
							</div>

							<div class="form-group">
								<label class="form-label">Privacy</label>
								<div class="form-check form-switch">
									<input
										class="form-check-input"
										type="checkbox"
										v-model="jurisdiction.public"
										:id="`j-public-${jurisdiction.id}`"
									/>
									<label class="form-check-label" :for="`j-public-${jurisdiction.id}`"
										>Public - make {{ jurisdiction.type }} available in muni list</label
									>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="form-group mb-3">
								<label class="form-label" for="jurisdiction-location">Location on map</label>
								<gmap-autocomplete :options="autocompleteOptions" @place_changed="setLocation">
									<template v-slot:default="slotProps">
										<input
											id="jurisdiction-location"
											class="form-control"
											ref="input"
											:value="jurisdiction.location.city"
											:placeholder="`${jurisdiction.type} location`"
											v-on="slotProps.listeners"
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<p class="mb-1">
								📍
								<strong
									>{{ jurisdiction.location.city }}, {{ jurisdiction.location.county }},
									{{ jurisdiction.location.state }}</strong
								>
							</p>
							<Gmap-Map
								class="mb-2"
								style="width: 100%; height: 300px;"
								:zoom="jurisdiction.location.zoom"
								:center="jurisdiction.location"
								@zoom_changed="updateLocationZoom"
							>
							</Gmap-Map>

							<p>
								<span class="text-capitalize">{{ jurisdiction.type }}</span> timezone is detected as
								<code>{{ jurisdiction.timezone }}</code>
							</p>

							<div class="form-group mb-3">
								<label class="form-label" for="mc_municipality_id">TownWeb CRM ID</label>
								<input
									type="text"
									inputmode="numeric"
									class="form-control form-control-sm"
									id="mc_municipality_id"
									v-model="jurisdiction.mc_municipality_id"
									placeholder="MC2 municiplality ID"
								/>
							</div>
						</div>
					</div>

					<hr class="bg-gray my-4" />

					<div class="row mb-3">
						<div class="col-md-6">
							<div class="form-group mb-3">
								<label class="form-label" for="features">Features</label>

								<div v-for="(label, feature) in features" :key="label" class="form-check form-switch">
									<input
										class="form-check-input"
										type="checkbox"
										:id="`j-${feature}-${jurisdiction.id}`"
										v-model="jurisdiction.features"
										:value="feature"
									/>
									<label
										class="form-check-label me-3 ps-2"
										:for="`j-${feature}-${jurisdiction.id}`"
										>{{ label }}</label
									>
								</div>
							</div>

							<div v-if="jurisdiction.features.includes('issues')" class="form-group">
								<label class="form-label" for="features-issues-privacy">Hey311 privacy</label>
								<select
									class="form-select"
									id="features-issues-privacy"
									v-model="jurisdiction.features_options.issues_privacy"
								>
									<option value="public">Public - show in progress requests</option>
									<option value="resolved">Show resolved requests</option>
									<option value="private">Private - Show only own requests and stats</option>
								</select>
							</div>
						</div>

						<div class="col-md-6">
							<h6>Billing</h6>

							<p>
								<a
									:href="`https://dashboard.stripe.com/customers/${jurisdiction.stripe_customer_id}`"
									target="_blank"
									><font-awesome-icon :icon="['fab', 'stripe-s']" class="me-1" /> View Stripe
									Customer</a
								>
							</p>

							<div class="form-group mb-3">
								<label class="form-label" for="jurisdiction-pricing">Plan / Bundle</label>

								<select class="form-select" id="jurisdiction-pricing" v-model="jurisdiction.pricing">
									<option value="99">$99/month</option>
									<option value="99+founding">$99/month + founding member</option>
									<option value="custom">Custom plan</option>
									<option value="townweb">TownWeb does billing</option>
									<option value="townweb-ods-lite">TownWeb ODS (LITE)</option>
									<option value="townweb-ods-full">TownWeb ODS (FULL)</option>
									<option value="clerk-minutes">ClerkMinutes</option>
									<option value="hey311">Free Hey311</option>
								</select>
							</div>
						</div>
					</div>

					<p class="text-center">
						<button class="btn btn-primary px-5">Update jurisdiction</button>
					</p>
				</form>
			</div>
		</div>

		<div class="card bg-danger-lighter">
			<div class="card-header">
				<h5 class="my-0">Danger zone <small class="text-muted">- These actions are irreversible 🤷</small></h5>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="form-group mb-3">
						<p class="card-text">
							<strong>Change jurisdiction slug.</strong> This will change the jurisdiction link & HeyGov
							links shared or posted anywhere will stop working.
						</p>
						<input class="form-control bg-ganger-lighter opacity-75" v-model="jurisdiction.slug" />
					</div>
				</div>

				<p class="card-text">
					<button class="btn btn-outline-dark" @click="updateJurisdiction(jurisdiction)">
						Change slug
					</button>
				</p>

				<p class="card-text">
					<strong>Delete {{ jurisdiction.name }} HeyGov account</strong> This will delete EVERYTHING related
					to this jurisdiction: departments, threads, forms, form requests...
				</p>
				<p class="card-text">
					<button class="btn btn-danger" @click="removeJurisdiction">Delete jurisdiction</button>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	data() {
		return {
			state: 'loading',
			jurisdiction: null,
		}
	},
	created() {
		this.loadJurisdiction()
	},
	computed: {
		...mapState(['j', 'features', 'apiUrl']),
		autocompleteOptions() {
			const options = {
				componentRestrictions: { country: 'us' },
				fields: ['address_components', 'geometry', 'place_id'],
				types: [],
			}

			if (['city', 'town', 'township', 'village'].includes(this.jurisdiction?.type)) {
				options.types.push('(cities)')
			}

			return options
		},
	},
	methods: {
		loadJurisdiction() {
			this.state = 'loading'

			heyGovApi.get(this.$route.params.jSlug).then(({ data }) => {
				this.jurisdiction = data
				this.state = 'loaded'
			}, handleResponseError('Failed to load jurisdiction ({error})'))
		},
		setLocation(place) {
			this.jurisdiction.location.place_id = place.place_id
			this.jurisdiction.location.lat = place.geometry.location.lat()
			this.jurisdiction.location.lng = place.geometry.location.lng()

			place.address_components.forEach(component => {
				if (component.types.includes('administrative_area_level_1')) {
					this.jurisdiction.location.state = component.short_name
				} else if (component.types.includes('administrative_area_level_2')) {
					this.jurisdiction.location.county = component.short_name || component.long_name
				} else if (component.types.includes('country')) {
					this.jurisdiction.location.country = component.short_name
				} else if (
					component.types.includes('locality') ||
					(!this.jurisdiction.location.city && component.types.includes('administrative_area_level_3'))
				) {
					this.jurisdiction.location.city = component.short_name || component.long_name
				}
			})

			// this.$store.commit('updateCurrentJurisdiction', jurisdiction)
		},
		updateLocationZoom(zoom) {
			this.jurisdiction.location.zoom = zoom
		},
		updateJurisdiction(jurisdiction) {
			heyGovApi
				.put(`/jurisdictions/${jurisdiction.id}`, {
					name: jurisdiction.name,
					website: jurisdiction.website,
					email: jurisdiction.email,
					population_count: jurisdiction.population_count,
					households_count: jurisdiction.households_count,
					public: jurisdiction.public,
					mc_municipality_id: jurisdiction.mc_municipality_id,
					location: jurisdiction.location,
					features: jurisdiction.features,
					features_options: jurisdiction.features_options,
					pricing: jurisdiction.pricing,
				})
				.then(res => {
					this.jurisdiction = res.data
					this.updateLocationZoom(this.jurisdiction.location.zoom)
					this.$router.push(`/heygov-admin/jurisdiction/${this.jurisdiction.slug}`).catch(() => {})
					Vue.toasted.success('Jurisdiction info is updated')
				})
		},
		removeJurisdiction() {
			if (confirm('For sure?')) {
				heyGovApi.delete(`/jurisdictions/${this.jurisdiction.id}`).then(() => {
					Vue.toasted.show(`"${this.jurisdiction.name}" is removed`)
					this.$router.push(`/heygov-admin/jurisdictions`)
				}, handleResponseError('Failed to remove jurisdiction {error}'))
			}
		},
	},
}
</script>
