<template>
	<div class="card">
		<div class="card-body">
			<p>
				This account is part of these jurisdictions:
			</p>

			<div class="card-table mb-4">
				<table class="table table-hover">
					<thead>
						<tr>
							<th>ID</th>
							<th>Jurisdiction</th>
							<th>Role</th>
							<th>Department</th>
							<th>Notifications</th>
							<th>Added on</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="jurisdiction in person.jurisdictions"
							:key="`${jurisdiction.id}-person-jurisdiction`"
						>
							<td>
								{{ jurisdiction.id }}
							</td>
							<td>
								{{ jurisdiction.jurisdiction_name }}
							</td>
							<td>
								<span
									class="badge badge-xs "
									:class="
										roles[jurisdiction.role] ? roles[jurisdiction.role].class : 'bg-light text-gray'
									"
									bs-data-toggle="tooltip"
								>
									{{ roles[jurisdiction.role] ? roles[jurisdiction.role].name : 'Citizen' }}
								</span>
							</td>
							<td>
								<span v-if="jurisdiction.department_id">[{{ jurisdiction.department_id }}]</span>
							</td>
							<td>
								<span
									v-for="(alerts, notif) in jurisdiction.notifications"
									:key="`notif-${jurisdiction.id}-${notif}`"
									class="badge bg-light text-gray me-1 mb-1"
									><span class="text-dark">{{ notif }}</span
									>: {{ alerts.join(', ') }}</span
								>
							</td>
							<td>
								{{ jurisdiction.created_at | dateLocal }}
							</td>
							<td>
								<button
									class="btn btn-sm text-danger"
									@click="removePersonFromJurisdiction(jurisdiction)"
								>
									Remove
								</button>
							</td>
						</tr>
					</tbody>
					<tr v-if="person.jurisdictions.length === 0">
						<td colspan="9" class="text-center py-3">
							<span class="text-muted fw-bolder">Person not in jurisdiction 🤷</span>
						</td>
					</tr>
				</table>
			</div>

			<div class="row justify-content-center">
				<div class="col-md-10 col-lg-6">
					<form @submit.prevent="addPersonToAnotherJurisdiction" class="bg-neutral-50 p-3 rounded">
						<h5>Add person to another jurisdiction</h5>

						<div class="form-group mb-3">
							<label class="form-label" for="select-jurisdiction">Jurisdiction</label>
							<select
								class="form-select form-select-sm"
								id="select-jurisdiction"
								required
								v-model="personData.jurisdiction_id"
								@change="loadDepartments"
							>
								<!-- <option v-for="j in jurisdictions" :key="`${j.slug}-jurisdiction`" :value="j.id"
									>{{ j.name }}
								</option> -->
							</select>
						</div>

						<div class="form-group mb-3">
							<label class="form-label" for="select-role">Role</label>
							<select
								class="form-select form-select-sm"
								id="select-role"
								required
								v-model="personData.role"
							>
								<option value="ADMIN">HeyGov Admin</option>
								<option value="EDITOR">Department Admin</option>
								<option value="WORKER">Department Member</option>
								<option value="CITIZEN">Citizen</option>
							</select>
						</div>

						<div v-if="personData.role !== 'CITIZEN' && departments.length" class="form-group mb-3">
							<label class="form-label" for="select-department">Department</label>

							<select
								class="form-select form-select-sm"
								id="select-department"
								required
								v-model="personData.department_id"
							>
								<option
									v-for="department in departments"
									:key="`${department.id}-jurisdiction`"
									:value="department.id"
									>{{ department.name }}
								</option>
							</select>

							<span
								v-if="states.departments === 'loading'"
								class="spinner-border spinner-border-sm"
								role="state"
							></span>
						</div>

						<p class="text-center card-text">
							<button class="btn btn-primary" disabled>Add to jurisdiction</button>
						</p>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	props: {
		person: Object,
	},
	name: 'PersonJurisdictions',
	data() {
		return {
			states: {
				departments: 'idle',
			},
			personData: {
				jurisdiction_id: '',
				role: 'CITIZEN',
				department_id: '',
			},
			departments: [],
		}
	},
	computed: {
		...mapState(['roles']),
	},
	methods: {
		addPersonToAnotherJurisdiction() {
			heyGovApi
				.post(`/admin/people/${this.person.id}/jurisdictions`, {
					jurisdiction_id: this.personData.jurisdiction_id,
					role: this.personData.role,
					department_id: this.personData.department_id,
				})
				.then(({ data }) => {
					this.person.jurisdictions.push(data)
					this.personData.jurisdiction_id = ''
					this.personData.department_id = ''
					this.personData.role = 'CITIZEN'
					Vue.toasted.success('Person added to jurisdiction')
				}, handleResponseError(`Failed to add person to jurisdiction ({error})`))
		},
		loadDepartments() {
			this.states.departments = 'loading'
			this.departments = []

			if (this.personData.jurisdiction_id) {
				heyGovApi.get(`${this.personData.jurisdiction_id}/departments`).then(({ data }) => {
					this.departments.push(...data)
					this.states.departments = 'loaded'
				}, handleResponseError(`Failed to load departments ({error})`))
			}
		},

		removePersonFromJurisdiction(jurisdictionPerson) {
			if (confirm(`Really want to remove this jurisdiction role?`)) {
				heyGovApi
					.delete(`/admin/people/${jurisdictionPerson.person_id}/jurisdictions/${jurisdictionPerson.id}`)
					.then(() => {
						this.person.jurisdictions = this.person.jurisdictions.filter(
							j => j.id !== jurisdictionPerson.id
						)
						Vue.toasted.show('Person removed from jurisdiction')
					}, handleResponseError(`Failed to remove person from jurisdiction ({error})`))
			}
		},
	},
}
</script>
