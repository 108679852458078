<template>
	<div class="threads-page">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">
					311 Requests <span class="badge bg-light text-dark">{{ pag.total }}</span>
				</h2>
			</div>
		</div>

		<div class="bar-filters border rounded bg-white p-1 mb-2">
			<div class="row align-items-center justify-content-around g-2">
				<!-- <div class="col">
					<input class="form-control" type="search" placeholder="🔍 Search" v-model="filters.search" />
				</div> -->
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.jurisdiction_id">
						<option value="">All jurisdiction</option>
						<option value="live">Real jurisdictions</option>
						<option
							v-for="jurisdiction in jurisdictions"
							:key="`${jurisdiction.id}-munipality`"
							:value="jurisdiction.id"
							>{{ jurisdiction.name }}</option
						>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.status">
						<option value="">Status</option>
						<option value="new">Received</option>
						<option value="working">Working on it</option>
						<option value="resolved">Resolved</option>
						<option value="closed">Declined</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.source">
						<option value="">Source</option>
						<option value="widget">Widget</option>
						<option value="app">App</option>
						<option value="android">Android</option>
						<option value="ios">iOS</option>
						<option value="web">Web</option>
						<option value="facebook">Facebook</option>
						<option value="messenger">Messenger</option>
					</select>
				</div>
				<div class="col">
					<select class="form-select form-select-sm" v-model="filters.date_range">
						<option value="30-days">Last 30 days</option>
						<option value="3-months">Last 3 months</option>
						<option value="12-months">Last 12 months</option>
					</select>
				</div>
			</div>
		</div>

		<div v-if="states.stats === 'idle'" class="row align-items-center my-3">
			<div class="col">
				<stats-line-chart :data="statsChart" :options="statsChartOptions" :height="250"></stats-line-chart>
			</div>
			<div class="col-auto">
				<stats-pie-chart
					:data="jurisdictionsChart"
					:options="jurisdictionChartOptions"
					:height="220"
				></stats-pie-chart>
			</div>
		</div>
		<div v-else class="text-center bg-light rounded-1 py-5 my-3" style="min-height: 250px">
			<p>Generating fresh stats for you</p>
			<span class="spinner-border" role="status"></span>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>ID</th>
								<th>Municipality</th>
								<th>Message</th>
								<th @click="sortTable('visibility', 'asc')" class="hover cursor-pointer">
									Privacy
									<small v-if="sorting.orderBy === 'visibility'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('status', 'asc')" class="hover cursor-pointer">
									Status
									<small v-if="sorting.orderBy === 'status'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('source', 'asc')" class="hover cursor-pointer">
									Source
									<small v-if="sorting.orderBy === 'source'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('created_at', 'desc')" class="hover cursor-pointer">
									Date
									<small v-if="sorting.orderBy === 'created_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="thread in threads" :key="thread.id">
								<td>
									<small class="text-muted" :title="thread.uuid">{{ thread.id }}</small>
								</td>
								<td>
									{{ truncateString(thread.jurisdiction_name, 25) }}
								</td>
								<td class="text-truncate" style="max-width: 300px;">
									<router-link :to="`/${thread.jurisdiction_slug}/threads/${thread.uuid}`">{{
										thread.title ||
											(thread.messages.length ? thread.messages[0].message : '(no title)')
									}}</router-link>
								</td>
								<td>
									<span
										class="badge text-capitalize"
										:class="
											thread.visibility === 'private'
												? 'bg-success-50 text-success-400'
												: 'bg-danger-50 text-danger-400'
										"
										@dblclick="updateThreadVisibility(thread)"
										>{{ thread.visibility }}</span
									>
								</td>
								<td>
									<span
										v-if="threadsStatuses[thread.status]"
										class="badge"
										:class="[
											threadsStatuses[thread.status].bgClass,
											threadsStatuses[thread.status].class,
										]"
									>
										{{ threadsStatuses[thread.status].name }}
									</span>
									<code v-else>{{ thread.status }}</code>
								</td>
								<td>{{ thread.source }}</td>
								<td>
									<time :datetime="thread.created_at">{{ thread.created_at | dateLocal }}</time>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.threads === 'loading'">
								<td colspan="9" class="text-center py-3">
									<div class="spinner-border spinner-border-sm" role="state"></div>
								</td>
							</tr>
							<tr v-else-if="states.threads === 'idle' && !threads.length">
								<td colspan="9" class="text-center py-3">
									<div v-if="hasActiveFilters">
										<img
											src="https://files.heygov.com/illustrations/illustration-arms-documents.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">No 311 requests. Try changing the filters</p>
									</div>
									<div v-else>
										<img
											src="https://files.heygov.com/illustrations/illustration-form-applications.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">Awaiting 311 requests</p>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div class="row">
					<div class="col">
						<span class="me-3 text-muted">Per page</span>
						<div class="d-inline-flex ">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div v-if="pag.total" class="col">
						Showing {{ pag.limit * (pag.page - 1) }}-{{ pag.limit * pag.page }} of {{ pag.total }}
					</div>
					<div class="col-auto">
						<nav aria-label="Page navigation example" v-if="pag.pages > 1">
							<ul class="pagination my-0">
								<!-- TODO add these buttons when there are > 20 pages -->

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>

								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a
										v-if="Math.abs(pag.page - p) < 5 || pag.page == pag.pages - 1 || p == 0"
										class="page-link"
										@click="pag.page = p"
									>
										{{ p }}
									</a>
								</li>

								<li v-if="pag.pages > 10" class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { format, parseISO } from 'date-fns'

import heyGovApi from '@/api.js'
import { truncateString, handleResponseError } from '@/utils.js'

import StatsLineChart from '@/components/StatsLineChart.vue'
import { chartsCommonData } from '../../actions/charts'
import StatsPieChart from '@/components/StatsPieChart.vue'

export default {
	components: { StatsLineChart, StatsPieChart },
	props: { jurisdictions: Array },
	name: 'AdminIssues',
	data() {
		return {
			states: {
				threads: 'loading',
				stats: 'loading',
			},
			threads: [],
			stats: [],
			filters: {
				date_range: this.$route.query.date_range || localStorage.getItem('admin-date-range') || '12-months',
				jurisdiction_id: this.$route.query.jurisdiction_id || 'live',
				search: this.$route.query.search || '',
				status: this.$route.query.status || '',
				source: this.$route.query.source || '',
			},
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				limit: localStorage.getItem('admin-threads-per-page') || 25,
			},
			sorting: {
				orderBy: localStorage.getItem('admin-threads-orderBy') || 'created_at',
				order: localStorage.getItem('admin-threads-order') || 'desc',
			},

			threadsStatuses: {
				new: {
					name: 'Received',
					class: 'text-warning',
					bgClass: 'bg-warning-lighter',
				},
				working: {
					name: 'In Progress',
					class: 'text-info',
					bgClass: 'bg-info-lighter',
				},
				resolved: {
					name: 'Resolved',
					class: 'text-success',
					bgClass: 'bg-success-lighter',
				},
				closed: {
					name: 'Declined',
					class: 'text-danger',
					bgClass: 'bg-danger-lighter',
				},
			},
			statsChart: {
				labels: [],
				datasets: [
					{
						label: 'Received',
						data: [],
						borderColor: '#FF5D46',
						backgroundColor: '#FFF3F1',
						pointBorderColor: '#FF5D46',
						pointBackgroundColor: '#FF5D46',
						...chartsCommonData,
					},
					{
						label: 'Resolved',
						data: [],
						borderColor: '#2A9836',
						backgroundColor: '#8dd384',
						pointBorderColor: '#2A9836',
						pointBackgroundColor: '#2A9836',
						...chartsCommonData,
					},
				],
			},
			statsChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						grid: {
							display: false,
						},
					},
				},
			},
			jurisdictionsChart: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [
							'rgb(109, 249, 177)',
							'rgb(52, 55, 226)',
							'rgb(21, 129, 237)',
							'rgb(46, 153, 160)',
							'rgb(4, 201, 109)',
							'rgb(255, 168, 30)',
							'rgb(46, 79, 201)',
							'rgb(255, 50, 64)',
							'rgb(62, 37, 221)',
							'rgb(82, 229, 143)',
							'rgb(198, 30, 216)',
							'rgb(43, 175, 70)',
							'rgb(206, 255, 102)',
							'rgb(88, 132, 0)',
							'rgb(142, 11, 22)',
							'rgb(203, 97, 244)',
						],
						borderWidth: 1,
						hoverOffset: 2,
					},
				],
			},
			jurisdictionChartOptions: {
				cutout: 50,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					title: {
						display: true,
						text: 'by Jurisdiction',
						font: 'Agradir',
					},
				},
			},
		}
	},
	created() {
		this.loadThreads()
	},
	computed: {
		hasActiveFilters() {
			return Object.values(this.filters).some(filter => filter)
		},
		jurisdictionsStatsHasData() {
			return Object.values(this.jurisdictionsChart.datasets[0].data).reduce((sum, curr) => sum + curr, 0)
		},
	},
	methods: {
		truncateString,

		loadThreads() {
			this.states.threads = 'loading'
			const params = {
				...this.filters,
				...this.sorting,
				limit: this.pag.limit,
				page: this.pag.page,
			}

			heyGovApi.get('admin/threads', { params }).then(data => {
				this.pag.total = data.headers['x-total']
				this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)
				this.threads = data.data
				this.states.threads = 'idle'
			})

			this.updatePageUrl()
			this.loadStats()
		},
		loadStats() {
			this.states.stats = 'loading'

			this.statsChart.labels = []
			this.jurisdictionsChart.labels = []
			this.statsChart.datasets[0].data = []
			this.statsChart.datasets[1].data = []
			this.jurisdictionsChart.datasets[0].data = []

			heyGovApi
				.get('admin/threads-stats', { params: this.filters })
				.then(data => {
					this.stats = data.data
					this.states.stats = 'idle'
					if (this.filters.date_range.includes('months')) {
						for (let m in this.stats.byMonth) {
							this.statsChart.labels.push(format(parseISO(`${m}-01`), 'MMM yyyy'))
							this.statsChart.datasets[0].data.push(this.stats.byMonth[m].count)
							this.statsChart.datasets[1].data.push(this.stats.byMonth[m].resolved)
						}
					} else {
						for (let c in this.stats.byDay) {
							this.statsChart.labels.push(format(parseISO(`${c}`), 'MMM dd'))
							this.statsChart.datasets[0].data.push(this.stats.byDay[c].count)
							this.statsChart.datasets[1].data.push(this.stats.byDay[c].resolved)
						}
					}

					for (let j in this.stats.byJurisdiction) {
						this.jurisdictionsChart.datasets[0].data.push(this.stats.byJurisdiction[j].count)
						if (this.jurisdictions && this.jurisdictions.length > 0) {
							this.jurisdictionsChart.labels.push(
								this.jurisdictions.find(jurisdiction => jurisdiction.id == j)?.name.substring(0, 25) ||
									'??'
							)
						}
					}
				})
				.catch(error => {
					let msg = error.response.data.message || error.message || 'Server error, please try again'
					if (error.response?.data?.fields) {
						msg += ': '
						msg += error.response.data.fields.map(field => `${field.param} - ${field.msg}`).join(', ')
					}
					Vue.toasted.error(msg)
				})
		},
		updatePageUrl() {
			let query = {}
			for (const filter in this.filters) {
				if (this.filters[filter]) {
					query[filter] = this.filters[filter]
				}
			}
			if (this.pag.page && this.pag.page != 1) {
				query.page = this.pag.page
			}
			this.$router.replace({ path: `/heygov-admin/issues-list`, query }).catch(() => {})
		},
		sortTable(orderBy, defaultOrder) {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.orderBy = orderBy
		},

		updateThreadVisibility(thread) {
			if (thread.visibility === 'public') {
				this.updateThread(thread, { visibility: 'private' })
			} else {
				this.updateThread(thread, { visibility: 'public' })
			}
		},
		updateThread(thread, toUpdate) {
			heyGovApi.put(`/admin/threads/${thread.id}`, toUpdate).then(() => {
				for (const key in toUpdate) {
					thread[key] = toUpdate[key]
				}
			}, handleResponseError('Error updating thread ({error})'))
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.pag.page = 1
				this.threads = []
				this.loadThreads()
			},
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page !== 1) {
					this.pag.page = 1
				} else {
					// otherwise reload data now
					this.threads = []
					this.loadThreads()
				}
				localStorage.setItem('admin-threads-orderBy', this.sorting.orderBy)
				localStorage.setItem('admin-threads-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.threads = []
			this.loadThreads()
		},
		'pag.limit'() {
			this.threads = []
			this.loadThreads()
			localStorage.setItem('admin-threads-per-page', this.pag.limit)
		},
	},
}
</script>
