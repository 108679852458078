<template>
	<div class="page-admin-workflows">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Global workflows</h2>
			</div>
			<div class="col-auto">
				<router-link to="/heygov-admin/workflows/create" class="btn btn-primary btn-sm btn-big-icon disabled">
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add workflow
				</router-link>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="btn-group mb-3">
					<button class="btn btn-sm btn-primary">All triggers</button>
					<button class="btn btn-sm btn-outline-primary" @click="tbi">Forms</button>
					<button class="btn btn-sm btn-outline-primary" @click="tbi">Meetings</button>
					<button class="btn btn-sm btn-outline-primary" @click="tbi">Payments</button>
					<button class="btn btn-sm btn-outline-primary" @click="tbi">Venues</button>
				</div>

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Workflow</th>
								<th>Trigger</th>
								<th>Actions</th>
								<th>Added by</th>
								<th>Runs</th>
								<th>Last run</th>
							</tr>
						</thead>
						<tbody>
							<router-link
								v-for="workflow in workflows"
								:key="workflow.id"
								:to="`/heygov-admin/workflows/${workflow.id}`"
								tag="tr"
							>
								<td>{{ workflow.name }}</td>
								<td>
									<code>{{ workflow.trigger }}</code>
								</td>
								<td>
									<span
										v-for="action in workflow.actions"
										:key="action.id"
										class="badge"
										:class="
											actions[action.action]
												? actions[action.action].class
												: 'bg-neutral-100 text-dark'
										"
										>{{
											actions[action.action] ? actions[action.action].name : action.action
										}}</span
									>
								</td>
								<td>[{{ workflow.created_by }}]</td>
								<td>{{ workflow.runs.toLocaleString() }}</td>
								<td>
									<span v-if="workflow.last_run_at">{{ workflow.last_run_at | dateToNow }}</span>
								</td>
							</router-link>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	name: 'AdminWorkflows',
	components: {},
	data() {
		return {
			states: {
				workflows: 'loading',
			},

			workflows: [],

			actions: {
				'send-email': {
					name: 'Send email',
					description: 'Send an email on behald of HeyGov',
					class: 'bg-primary-50 text-primary-400',
				},
			},
		}
	},

	created() {
		this.loadWorkflows()
	},
	computed: {
		...mapState(['apiUrl']),
	},
	methods: {
		loadWorkflows() {
			this.workflows = []
			this.states.workflows = 'loading'

			heyGovApi.get('super-secret-admin-endpoints/workflows').then(({ data }) => {
				this.workflows.push(...data)
			}, handleResponseError('Failed to load workflows ({error})'))
		},
		tbi() {
			Vue.toasted.show('Hey don\t touch that')
		},
	},
	watch: {},
}
</script>
