<template>
	<div>
		<div class="row">
			<div class="col col-md-6 col-lg-5">
				<div class="card mb-4">
					<div class="card-header py-2">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="my-0">Account details</h5>

							<router-link
								class="btn btn-sm btn-outline-dark float-end"
								:to="`/heygov-admin/people/${person.id}/edit`"
								>✏️ Edit</router-link
							>
						</div>
					</div>
					<div class="card-body">
						<div class="card-table mb-3">
							<table class="table table-borderlesss table-hover mb-0">
								<tbody>
									<tr>
										<th>
											Name
										</th>
										<td>
											<span v-if="person.first_name || person.last_name">{{
												[person.first_name, person.middle_name, person.last_name]
													.filter(Boolean)
													.join(' ')
											}}</span>
											<small v-else class="text-neutral-300">-</small>
										</td>
									</tr>
									<tr>
										<th>Email</th>
										<td>
											<span v-if="person.email">{{ person.email }}</span>
											<small v-else class="text-neutral-300">-</small>
										</td>
									</tr>
									<tr>
										<th>
											Password
										</th>
										<td>
											<small v-if="!person.email" class="text-warning-300"
												>(email is required first)</small
											>
											<button
												v-else-if="person.hasPassword"
												class="btn btn-sm btn-outline-dark"
												:disabled="true"
											>
												Send pass reset
											</button>
											<template v-else>
												<router-link
													class="btn btn-sm btn-outline-info me-1"
													:to="`/heygov-admin/people/${person.id}/password`"
												>
													Create password
												</router-link>
												<button class="btn btn-sm btn-outline-info" disabled>
													Send "Create pass" email
												</button>
											</template>
										</td>
									</tr>
									<tr>
										<th>Bio</th>
										<td>
											{{ person.bio }}
										</td>
									</tr>
									<tr>
										<th>Phone</th>
										<td>
											{{ person.phone }}
										</td>
									</tr>
									<tr>
										<th>Date of birth</th>
										<td>
											<span v-if="person.dob"
												>{{ person.dob | dateLocal }} ({{
													formatDistanceToNow(new Date(person.dob))
												}}
												old)</span
											>
										</td>
									</tr>
									<tr>
										<th>Address</th>
										<td>
											{{ person.address }}
										</td>
									</tr>
									<tr>
										<th>Week start</th>
										<td>
											{{ person.start_of_week }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<p class="card-text text-muted">
							Last account update: {{ person.updated_at | dateLocal }},
							{{ formatDistanceToNow(new Date(person.updated_at), { addSuffix: true }) }}
						</p>
					</div>
				</div>
			</div>

			<div class="col-md-6 col-lg-7">
				<div class="card mb-4">
					<div class="card-header">
						<h5 class="my-0">Sessions</h5>
					</div>
					<gmap-map style="width: 100%; height: 250px" :zoom="mapZoom" :center="mapCenter" ref="mapLocations">
						<gmap-marker
							v-for="(location, index) in mapMarkers"
							:key="`location-ip-${index}`"
							:position="location"
							:label="location.icon"
						></gmap-marker>
					</gmap-map>

					<div class="card-body">
						<div class="card-table">
							<table class="table table-sm table-hover mb-0">
								<thead>
									<tr>
										<th>Event</th>
										<th>Date</th>
										<th>Device</th>
										<th>IP / Loc</th>
										<th></th>
									</tr>
								</thead>

								<tbody>
									<tr
										v-for="token in person.authTokens"
										:key="token.id"
										class="on-parent"
										:class="{ 'bg-danger-lighter': !token.active }"
									>
										<td>
											<strong>{{ token.action }}</strong>
										</td>
										<td>{{ token.created_at | dateLocal }}</td>
										<td>
											<p class="mb-1">
												{{
													['ios', 'android'].includes(token.from_platform) ||
													(token.ua.device && token.ua.device.type === 'mobile')
														? '📱'
														: '🖥'
												}}
												{{ token.from_platform }}
											</p>
											<p class="mb-0">
												{{
													[
														token.ua.browser.name,
														token.ua.os.name,
														token.ua.device ? token.ua.device.vendor : null,
														token.ua.device ? token.ua.device.model : null,
													]
														.filter(Boolean)
														.join(' / ')
												}}
											</p>
										</td>
										<td>
											<p class="text-truncate mb-1" style="max-width: 150px">
												<a :href="`https://ipinfo.io/${token.from_ip}`" target="_blank">{{
													token.from_ip
												}}</a>
											</p>
											<p class="mb-0">
												{{
													[token.ip_info.city, token.ip_info.region, token.ip_info.country]
														.filter(Boolean)
														.join(', ')
												}}
											</p>
										</td>
										<td>
											<button v-if="token.active" class="btn btn-sm text-danger">disable</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card bg-danger-lighter">
			<div class="card-header">
				<h5 class="my-0">Danger zone <small class="text-muted">- These actions are irreversible 🤷</small></h5>
			</div>
			<div class="card-body">
				<p class="card-text">
					<strong>Delete - HeyGov account</strong> This will delete EVERYTHING related to this person:
					departments, form applications, venue bookings, Hey311 requests, etc.
				</p>
				<p class="card-text">
					<button class="btn btn-danger" @click="deletePerson(person.id)">Delete account</button>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'
import { mapState } from 'vuex'
import { formatDistanceToNow } from 'date-fns'
import { getGoogleMapsAPI } from 'gmap-vue'

export default {
	name: 'AdminPersonGeneral',
	props: {
		person: Object,
	},
	data() {
		return {
			mapCenter: { lat: 36, lng: -101 },
			mapZoom: 3,
			mapMarkers: [],
		}
	},
	created() {
		this.doAuthTokenLocations()
		this.doAddressLocation()
	},
	computed: {
		...mapState(['j']),
		google: getGoogleMapsAPI,
	},
	methods: {
		formatDistanceToNow,

		doAddressLocation() {
			if (this.person.address) {
				// wait for google maps api to be available
				setTimeout(() => {
					const geocoder = new this.google.maps.Geocoder()

					geocoder.geocode({ address: this.person.address }, (results, status) => {
						if (status === 'OK') {
							this.mapMarkers.push({
								lat: results[0].geometry.location.lat(),
								lng: results[0].geometry.location.lng(),
								icon: 'A',
								label: results[0].formatted_address,
								// TODO add proper icon url
							})
							this.recenterMap()
						}
					})
				}, 500)
			}
		},
		doAuthTokenLocations() {
			const locs = []

			this.person.authTokens.forEach(token => {
				if (token.ip_info.loc && !locs.includes(token.ip_info.loc)) {
					locs.push(token.ip_info.loc)
				}
			})

			this.mapMarkers.push(
				...locs.map(loc => {
					loc = loc.split(',')

					return {
						lat: Number(loc[0]),
						lng: Number(loc[1]),
						icon: 'I',
						label: 'auth token',
					}
				})
			)

			this.recenterMap()
		},
		recenterMap() {
			if (this.mapMarkers.length > 1) {
				setTimeout(() => {
					const bounds = new this.google.maps.LatLngBounds()
					this.mapMarkers.forEach(location => {
						bounds.extend(location)
					})
					this.$refs.mapLocations.fitBounds(bounds)
				}, 500)
			} else if (this.mapMarkers.length === 1) {
				this.mapCenter = this.mapMarkers[0]
				this.mapZoom = 9
			}
		},

		deletePerson(id) {
			if (confirm('For sure remove this person?')) {
				heyGovApi.delete(`/admin/people/${id}`).then(
					() => {
						this.$router.push('/heygov-admin/people')
						Vue.toasted.error(`Person deleted from database`)
					},
					error => {
						Vue.toasted.error(`Error deleting person ~ ${error}`)
					}
				)
			}
		},
		deactivateAuthToken(authToken) {
			heyGovApi
				.put(`/admin/people/${this.person.id}/auth-token/${authToken.id}`, {
					active: false,
				})
				.then(({ data }) => {
					authToken.active = data.active
					Vue.toasted.success('Auth token was disabled')
				})
		},
	},
}
</script>
