<template>
	<div class="page-admin">
		<ul v-if="!$route.meta.embed" class="nav nav-tabs mb-4">
			<li class="nav-item">
				<router-link to="/heygov-admin" class="nav-link" :class="{ active: $route.name === 'AdminDashboard' }"
					>Dash</router-link
				>
			</li>
			<li class="nav-item">
				<router-link
					to="/heygov-admin/jurisdictions"
					class="nav-link"
					:class="{ active: $route.name.startsWith('AdminJurisdictions') }"
					>HeyGovs</router-link
				>
			</li>
			<li class="nav-item">
				<router-link
					to="/heygov-admin/people"
					class="nav-link"
					:class="{ active: $route.name.startsWith('AdminPeople') || $route.name.startsWith('AdminPerson') }"
					>People</router-link
				>
			</li>
			<li class="nav-item">
				<router-link
					to="/heygov-admin/payments-list"
					class="nav-link"
					:class="{ active: $route.name === 'AdminPayments' }"
					>Payments</router-link
				>
			</li>
			<li class="nav-item">
				<router-link
					to="/heygov-admin/issues-list"
					class="nav-link"
					:class="{ active: $route.name === 'AdminIssues' }"
					>311 requests</router-link
				>
			</li>
			<li class="nav-item">
				<router-link
					to="/heygov-admin/bookings-list"
					class="nav-link"
					:class="{ active: $route.name === 'AdminBookings' }"
					>Reservations</router-link
				>
			</li>
			<li class="nav-item">
				<router-link to="/heygov-admin/forms" class="nav-link" :class="{ active: $route.name === 'AdminForms' }"
					>Forms</router-link
				>
			</li>
			<li class="nav-item">
				<router-link
					to="/heygov-admin/form-requests-list"
					class="nav-link"
					:class="{ active: $route.name === 'AdminFormRequests' }"
					>Submissions</router-link
				>
			</li>
			<li class="nav-item">
				<router-link
					to="/heygov-admin/workflows"
					class="nav-link"
					:class="{ active: $route.name.startsWith('AdminWorkflows') }"
					>Workflows</router-link
				>
			</li>
		</ul>

		<router-view></router-view>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters(['isStaff']),
	},
	created() {
		if (!this.isStaff) {
			this.$router.push(`/not-found`)
		}
	},
}
</script>
